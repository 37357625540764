import { RealTimePrice } from './realTimePrice';

export function SumTotal(cart, cartData, user, showOnlySelectedOnes) {
  let result = 0;
  for (let i = 0; i < cart.length; i++) {
    const elem = cart[i];
    if (showOnlySelectedOnes) {
      for (let i = 0; i < cartData?.length; i++) {
        const data = cartData[i];
        if (data._id === elem.product_id && data.checked) {
          let price = RealTimePrice(data, user);
          result += elem.quantity * price;
        }
      }
    } else {
      for (let i = 0; i < cartData?.length; i++) {
        const data = cartData[i];
        if (data._id === elem.product_id) {
          let price = RealTimePrice(data, user);
          result += elem.quantity * price;
        }
      }
    }
  }
  return +result.toFixed(1);
}
