const config = {
  api: {
    aboutUsPage: 'about-us-page',
    aboutUsPrivileges: 'about-us-privileges',
    banner: 'banner',
    delivery_state: 'delivery_state',
    delivery_city: 'delivery_city',
    homepage: 'home-page',
    contactPage: 'contact-page',
    contactPart: 'contact-part',
    contactType: 'contact-type',
    corporatePage: 'corporate-page',
    socialLink: 'social-link',
    category: 'category',
    client: 'client',
    clientGroup: 'client-group',
    deliveryPage: 'delivery-page',
    fishcoinPage: 'fishcoin-page',
    market: 'market',
    marketPage: 'market-page',
    order: 'order',
    product: 'product',
    footer: 'footer',
    privacyPolicy: 'privacy-policy',
    sale: 'sale',
    salePage: 'sale-page',
    tag: 'tag',
    termsOfService: 'terms-of-service',
    user: 'user',
    uploads: 'uploads',
    MAINAPP_URL: process.env.REACT_APP_MAINAPP_URL,
    API_URL: process.env.REACT_APP_API_URL,
    TELCELL_API_URL: process.env.TELCELL_API_URL,
    IDRAM_API_URL: process.env.IDRAM_API_URL,
    CHAT_URL: process.env.REACT_APP_CHAT_URL,
    API_Version: 'v4',
  },
  authentication: {
    loginKeyFile: '/login/client-keyFile',
    loginToken: '/login/client-token',
    verifyKeyFile: '/verify/client-keyFile',
    verifyToken: '/verify/client-token',
    cookieKeyFile: 'keyFile',
    cookieToken: 'client-token',
    verifySMS: 'verify-SMS',
    registerClient: 'register-client',
    registerOrder: 'register-order',
    updateClient: 'update-client',
    key: process.env.REACT_APP_API_KEY,
  },
  copyright: {
    en: `All Rights Reserved | © Icefish ${new Date().getFullYear()}`,
    ru: `Авторские права защищены | © Icefish ${new Date().getFullYear()}`,
    hy: `Հեղինակային իրավունքները պաշտպանված են | © Icefish ${new Date().getFullYear()}`,
  },
  developedBy: {
    logoUrl: 'https://api.deepmindsystems.com/logo.svg',
    name: 'Deepmindsystems',
    text: {
      en: `Designed and Developed by `,
      ru: `Спроектировано и разработано `,
      hy: `Նախագծված և մշակված է `,
    },
  },
  brand: {
    name: 'Icefish.am',
    link: 'https://www.icefish.com',
    logo: '/Favicon.png',
  },
  privacyPolicy: {
    name: {
      en: 'Privacy Policy',
      ru: 'Политика конфиденциальности',
      hy: 'Գաղտնիության քաղաքականություն',
    },
    link: '/privacy-policy',
  },
  termsOfService: {
    name: {
      en: 'Terms of Service',
      ru: 'Условия использования',
      hy: 'Ծառայությունների մատուցման պայմաններ',
    },
    link: '/terms-of-service',
  },
  swal: {
    successOrder: function (lang, ID) {
      let result = {
        en: {
          icon: 'success',
          title: 'Great',
          text: `The order ${
            ID ? 'number ' + ID : ''
          } has been accepted, our operators will be in touch soon`,
        },
        ru: {
          icon: 'success',
          title: 'Отлично',
          text: `Заказ ${
            ID ? 'по номеру ' + ID : ''
          } принят, наши операторы свяжутся в ближайшее время`,
        },
        hy: {
          icon: 'success',
          title: 'Շատ լավ',
          text: `Պատվեր${
            ID ? ' համար ' + ID + '-ը' : 'ը'
          } ընդունվել է, մեր օպերատորները շուտով կապի դուրս կգան`,
        },
      };

      return result[lang];
    },
    success: {
      en: {
        icon: 'success',
        title: 'Great',
        text: 'Your message was sent',
      },
      ru: {
        icon: 'success',
        title: 'Отлично',
        text: 'Ваше сообщение было отправлено',
      },
      hy: {
        icon: 'success',
        title: 'Շատ լավ',
        text: 'Ձեր հաղորդագրությունն ուղարկվել է',
      },
    },
    successfullyUpdated: {
      en: {
        icon: 'success',
        title: 'Great',
        text: 'Successfully Updated',
      },
      ru: {
        icon: 'success',
        title: 'Отлично',
        text: 'Успешно обновлено',
      },
      hy: {
        icon: 'success',
        title: 'Շատ լավ',
        text: 'Հաջողությամբ թարմացվեց',
      },
    },
    websiteForm: {
      en: {
        icon: 'success',
        title: 'Great',
        text: 'Your request was sent, we sent back the information for further steps, please check your Email address.',
      },
      ru: {
        icon: 'success',
        title: 'Отлично',
        text: 'Ваш запрос был отправлен, мы отправили информацию для дальнейших действий, проверьте свой адрес электронной почты.',
      },
      hy: {
        icon: 'success',
        title: 'Շատ լավ',
        text: 'Ձեր հարցումն ուղարկվել է, մենք հետ ենք ուղարկել տեղեկատվությունը հետագա քայլերի համար, խնդրում ենք ստուգել ձեր էլ. Փոստի հասցեն:',
      },
    },
    websiteFormError: (args, lang) => {
      return {
        en: {
          icon: 'error',
          title: 'Oops',
          text: `Error code #101, You entered the following fields incorrectly: ${args?.map(
            (arg) => arg.param.en
          )}, try again․ If the problem is not clear please contact support by websites form or sending message to support@icefish.com email address`,
        },
        ru: {
          icon: 'error',
          title: 'Ой',
          text: `Код ошибки #101, Вы неправильно ввели следующие поля: ${args?.map(
            (arg) => arg.param.ru
          )}, попробуйте еще раз. В случае, если проблема не ясна обратитесь в службу поддержки через форму на веб-сайте или отправив сообщение на адрес электронной почты support@icefish.com`,
        },
        hy: {
          icon: 'error',
          title: 'Սխալմունք',
          text: `Սխալ եք մուտքագրել հետևյալ դաշտերը՝ ${args?.map(
            (arg) => arg.param.hy
          )}, կրկին փորձեք: Ծագած խնդրի ոչ պարզ լինելու պարագայում դիմեք սպասարկման կենտրոն օգտագործելով կայքի հետադաչձ կապի միջոցը կամ ուղղարկեք էլ նամակ support@icefish.com հասցեին`,
        },
      };
    },
    thereIsNoUser: {
      en: {
        icon: 'error',
        title: 'Oops',
        text: 'Theres is no user with provided crendentials!',
      },
      ru: {
        icon: 'error',
        title: 'Ой',
        text: 'Нет пользователя с указанными учетными данными!',
      },
      hy: {
        icon: 'error',
        title: 'Մի փոքր խնդիր',
        text: 'Նշված տվյալներով օգտատեր չկա',
      },
    },
    error: {
      en: {
        icon: 'error',
        title: 'Oops',
        text: 'You have to fill all fields!',
      },
      ru: {
        icon: 'error',
        title: 'Ой',
        text: 'Вы должны заполнить все поля!',
      },
      hy: {
        icon: 'error',
        title: 'Սխալմունք',
        text: 'Դուք պետք է ճիշտ լրացնեք բոլոր դաշտերը:',
      },
    },
    errorCheckListMustBeSelected: {
      en: {
        icon: 'error',
        title: 'Oops',
        text: 'You have to select at least one product!',
      },
      ru: {
        icon: 'error',
        title: 'Ой',
        text: 'You have to select at least one product!',
      },
      hy: {
        icon: 'error',
        title: 'Սխալմունք',
        text: 'You have to select at least one product',
      },
    },
    youMustBeRegistered: {
      en: {
        icon: 'warning',
        title: 'Oops',
        text: 'You must register to use this feature.',
        footer: '<a href="/login">Click here to register</a>',
      },
      ru: {
        icon: 'warning',
        title: 'Ой',
        text: 'Вы должны зарегистрироваться, чтобы использовать эту функцию.',
        footer: '<a href="/login">Нажмите здесь, чтобы зарегистрироваться</a>',
      },
      hy: {
        icon: 'warning',
        title: 'Սխալմունք',
        text: 'Դուք պետք է գրանցվեք, օգտվելու համար այս հնարավորությունից:',
        footer: '<a href="/login">Գրանցվելու համար սեղմեք այստեղ</a>',
      },
    },
    errorOrder: {
      en: {
        icon: 'error',
        title: 'Oops',
        text: 'You have to fill all fields!',
      },
      ru: {
        icon: 'error',
        title: 'Ой',
        text: 'Вы должны заполнить все поля!',
      },
      hy: {
        icon: 'error',
        title: 'Սխալմունք',
        text: 'Խնդիր կա, գրեք support@icefish.am մեյլին:',
      },
    },
    passwordsNotMatch: {
      en: {
        icon: 'error',
        title: 'Oops',
        text: 'Passwords do not match!',
      },
      ru: {
        icon: 'error',
        title: 'Ой',
        text: 'Пароли не совпадают!',
      },
      hy: {
        icon: 'error',
        title: 'Սխալմունք',
        text: 'Գաղտնաբառերը չեն համապատասխանում:',
      },
    },
    usernameIsBusy: {
      en: {
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
        title: 'Oops',
        text: 'The username already taken, choose another one',
      },
      ru: {
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
        title: 'Ой',
        text: 'Имя пользователя уже занято, выберите другое!',
      },
      hy: {
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
        title: 'Վայ',
        text: 'Օգտագործողի անունն արդեն զբաղված է, ընտրեք մեկ ուրիշը:',
      },
    },
    emailIsBusy: {
      en: {
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
        title: 'Oops',
        text: 'The email already taken, choose another one',
      },
      ru: {
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
        title: 'Ой',
        text: 'Мейл уже занято, выберите другое!',
      },
      hy: {
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
        title: 'Վայ',
        text: 'Էլ հասցեն արդեն զբաղված է, ընտրեք մեկ ուրիշը:',
      },
    },
    areYouSure: {
      en: {
        icon: 'warning',
        timer: 3000,
        showConfirmButton: false,
        title: 'But',
        text: 'Are you sure?',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      },
      ru: {
        icon: 'warning',
        timer: 3000,
        showConfirmButton: false,
        title: 'Но',
        text: 'Ты уверен?',
        confirmButtonText: 'Да, удалить!',
        cancelButtonText: 'Нет, отменить!',
      },
      hy: {
        icon: 'warning',
        timer: 3000,
        showConfirmButton: false,
        title: 'Բայց',
        text: 'Արդյո՞ք, համոզվա՞ծ եք',
        confirmButtonText: 'Այո, ջնջիր այն։',
        cancelButtonText: 'Ոչ, չեղարկել',
      },
    },
  },
  translate: {
    showMore: {
      en: 'Show more',
      ru: 'Показать больше',
      hy: 'Ցույց տալ ավելին',
    },
    sale: {
      en: 'Sale',
      ru: 'Скидка',
      hy: 'Զեղչ',
    },
    spendFishCoin: {
      en: 'Spend fishcoin',
      ru: 'Использовать fishcoin',
      hy: 'Օգտագործել fishcoin',
    },
    spendedFishCoin: {
      en: 'Spended fishcoin',
      ru: 'Использованные fishcoin',
      hy: 'Օգտագործված fishcoin',
    },
    placeholder: {
      address: {
        en: '42 Movses Khorenatsi Street',
        ru: 'Улица Мовсеса Хоренаци 42',
        hy: 'Մովսես Խորենացու փողոց, 42',
      },
      password: {
        en: 'Minimum 8 characters',
        ru: 'Минимум 8 символов',
        hy: 'Ամենաքիչը 8 նշան',
      },
    },
    writeDesc: {
      en: 'Write text',
      ru: 'Написать текст',
      hy: 'Գրել տեքստ',
    },
    cartIsEmpty: {
      en: 'Your cart is empty',
      ru: 'Ваша корзина пуста',
      hy: 'Ձեր զամբյուղը դատարկ է',
    },
    kg: {
      en: 'Kg',
      ru: 'Кг',
      hy: 'Կգ',
    },
    canRegister: {
      en: 'Can you register at once',
      ru: 'Можно сразу зарегистрироваться',
      hy: 'Կարող եք միանգամից գրանցվել',
    },
    reOrder: {
      en: 'Re order',
      ru: 'Повторить заказ',
      hy: 'Կրկնել պատվերը',
    },
    myOrder: {
      en: 'My order',
      ru: 'Мой заказ',
      hy: 'Իմ պատվերը',
    },
    checkAll: {
      en: 'Check all',
      ru: 'Пометить все',
      hy: 'Նշել բոլորը',
    },
    goToHomePage: {
      en: 'Go to Homepage',
      ru: 'Перейди на домашнюю страницу',
      hy: 'Գնալ գլխավոր էջ',
    },
    register: {
      en: 'Register',
      ru: 'Зарегистрироваться',
      hy: 'Գրանցվել',
    },
    repeatPassword: {
      en: 'Repeat password',
      ru: 'Повторить пароль',
      hy: 'Կրկնել գաղտնաբառը',
    },
    socialPopupText: {
      en: 'Select the platform you would like to add to your data',
      ru: 'Выберите платформу, которую вы хотите добавить к своим данным',
      hy: 'Նշեք հարթակը որը կուզենաք ավելացնել Ձեր տվյալների մեջ',
    },
    bonus: {
      en: 'Bonus',
      ru: 'Бонусы',
      hy: 'Բոնուսներ',
    },
    logIn: {
      en: 'Log in',
      ru: 'Войти',
      hy: 'Մուտք գործել',
    },
    myAccount: {
      en: 'My account',
      ru: 'Мой аккаунт',
      hy: 'Իմ էջը',
    },
    logOut: {
      en: 'Log out',
      ru: 'Выйти',
      hy: 'Դուրս գալ',
    },
    notSelected: {
      en: 'Not selected',
      ru: 'Не выбран',
      hy: 'Ընտրված չէ',
    },
    deliverer: {
      en: 'To the deliverer',
      ru: 'Курьеру',
      hy: 'Առաքիչին',
    },
    searchText: {
      en: 'Write what you are looking for',
      ru: 'Напишите, что вы ищете',
      hy: 'Գրեք, ինչ եք փնտրում',
    },
    deliveryComment: {
      en: 'Shipping Notes',
      ru: 'Примечания по доставке',
      hy: 'Առաքման մասին նշումներ',
    },
    delivery: {
      en: 'Delivery',
      ru: 'Доставка',
      hy: 'Առաքում',
    },
    online: {
      en: 'Online',
      ru: 'Օнлайн',
      hy: 'Առցանց',
    },
    fromTheStore: {
      en: 'From the store',
      ru: 'С магазина',
      hy: 'Խանութից',
    },
    product: {
      en: 'product',
      ru: 'продуктов',
      hy: 'ապրանք',
    },
    shipment: {
      en: 'Shipment',
      ru: 'Отгрузка',
      hy: 'Առաքում',
    },
    paymentType: {
      en: 'Payment type',
      ru: 'Способ оплаты',
      hy: 'Վճարման տեսակ',
    },
    orderType: {
      en: 'Order type',
      ru: 'Тип заказа',
      hy: 'Պատվերի տեսակ',
    },
    dayAndTime: {
      en: 'Day and time',
      ru: 'День и время',
      hy: 'Օր և ժամ',
    },
    productsComingSoon: {
      en: 'Products coming soon.',
      ru: 'Товары скоро будут.',
      hy: 'Ապրանքները շուտով կլինեն:',
    },
    personalInfo: {
      en: 'Personal info',
      ru: 'Личная информация',
      hy: 'Անձնական տվյալներ',
    },
    fishcoinTill: {
      en: 'Active till',
      ru: 'Действует',
      hy: 'Ուժի մեջ է',
    },
    myFavorites: {
      en: 'My favorites',
      ru: 'Мои выборы',
      hy: 'Իմ ընտրածները',
    },
    myBonuses: {
      en: 'My bonuses',
      ru: 'Мои бонусы',
      hy: 'Իմ բոնուսները',
    },
    myOrders: {
      en: 'My orders',
      ru: 'Мои заказы',
      hy: 'Իմ պատվերները',
    },
    searchText: {
      en: 'Write what you are looking for',
      ru: 'Напишите, что вы ищете',
      hy: 'Գրեք, ինչ եք փնտրում',
    },
    dropzoneText: {
      en: 'There is no file, click to upload a new one',
      ru: 'Файла нет, нажмите, чтобы загрузить новыйe',
      hy: 'Ֆայլ չկա, սեղմեք՝ նորը վերբեռնելու համար',
    },
    linkCopied: {
      en: 'Link copied',
      ru: 'Ссылка скопирована',
      hy: 'Լինկը պատճենվել է',
    },
    quantity: {
      en: 'Quantity',
      ru: 'Количество',
      hy: 'Քանակ',
    },
    addToList: {
      en: 'Add to list',
      ru: 'Добавить в лист',
      hy: 'Ավելացնել լիստում',
    },
    noContent: {
      en: 'There is no content',
      ru: 'Нет контента',
      hy: 'Բովանդակություն չկա',
    },
    submit: {
      en: 'Submit',
      ru: 'Подтвердить',
      hy: 'Հաստատել',
    },
    submitSMS: {
      en: 'Submit sms text',
      ru: 'Вводите текст из смс',
      hy: 'Մուտքագրեք sms-ի տեքստը',
    },
    smsError: {
      en: 'Submit sms text',
      ru: 'Вводите текст из смс',
      hy: 'Մուտքագրեք sms-ի տեքստը',
    },
    paymentMethod: {
      en: 'Payment method',
      ru: 'Метод оплаты',
      hy: 'Վճարման եղանակ',
    },
    paymentSystem: {
      en: 'Payment System',
      ru: 'Платежная система',
      hy: 'Վճարային համակարգ',
    },
    deliveryMethod: {
      en: 'Delivery method',
      ru: 'Способ доставки',
      hy: 'Առաքման եղանակը',
    },
    deliveryAddress: {
      en: 'Delivery Address',
      ru: 'Адресс доставки',
      hy: 'Առաքման հասցե',
    },
    username: {
      en: 'Username',
      ru: 'Имя пользователя',
      hy: 'Օգտատիրոջ անուն',
    },
    login: {
      en: 'Sign in to start a session',
      ru: 'Войдите, чтобы начать сеанс',
      hy: 'Մուտք գործեք՝ սկսելու համար',
    },
    loginButton: {
      en: 'Log in',
      ru: 'Войти',
      hy: 'Մուտք',
    },
    supportText: {
      en: 'For all questions write',
      ru: 'По всем вопросам пишите',
      hy: 'Բոլոր հարցերի համար գրեք',
    },
    valute: {
      en: '\u058F',
      ru: '\u058F',
      hy: '\u058F',
    },
    department: {
      en: 'Departments',
      ru: 'Разделы',
      hy: 'Բաժիններ',
    },
    contacts: {
      en: 'Contacts',
      ru: 'Контакты',
      hy: 'Կոնտակտներ',
    },
    thereIsNoProduct: {
      en: 'The products was not found',
      ru: 'Товары не найдены',
      hy: 'Ապրանքներ չեն գտնվել',
    },
    thereIsNoOrder: {
      en: 'There is no orders',
      ru: 'Товары не найдены',
      hy: 'Պատվերներ չկան',
    },
    tryItToo: {
      en: 'Try it too',
      ru: 'Попробуйте тоже',
      hy: 'Փորձեք նաև',
    },
    all: {
      en: 'The all',
      ru: 'Все',
      hy: 'Բոլորը',
    },
    ourMainAdvantages: {
      en: 'Our main advantages',
      ru: 'Наши основные преимущества',
      hy: 'Մեր գլխավոր առավելությունները',
    },
    incorrectLogin: {
      en: 'You entered an incorrect username or password',
      ru: 'Вы ввели неверный логин или пароль',
      hy: 'Դուք մուտքագրել եք սխալ օգտվողի անուն կամ գաղտնաբառ',
    },
    address: {
      en: 'Address',
      ru: 'Адрес',
      hy: 'Հասցե',
    },
    password: {
      en: 'Password',
      ru: 'Пароль',
      hy: 'Գաղտնաբառ',
    },
    recoverPassword: {
      en: 'Lost your password?',
      ru: 'Забыли пароль?',
      hy: 'Չե՞ք հիշում գաղտնաբառը',
    },
    recoveredPassword: {
      en: 'Password recovered',
      ru: 'Пароль восстановлен',
      hy: 'Գաղտնաբառը վերականգնվել է',
    },
    name: {
      en: 'Name',
      ru: 'Имя',
      hy: 'Անուն',
    },
    theBasketIsEmpty: {
      en: 'The basket is empty',
      ru: 'Корзина пуста',
      hy: 'Զամբյուղը դատարկ է',
    },
    firstName: {
      en: 'First Name',
      ru: 'Имя',
      hy: 'Անուն',
    },
    lastName: {
      en: 'Last Name',
      ru: 'Фамилия',
      hy: 'Ազգանուն',
    },
    dateOfBirth: {
      en: 'Date Of Birth',
      ru: 'Дата рождения',
      hy: 'Ծննդյան ամսաթիվ',
    },
    desc: {
      en: 'Description',
      ru: 'Оисание',
      hy: 'Նկարագրություն',
    },
    subDesc: {
      en: 'Sub Description',
      ru: 'Под описание',
      hy: 'Ենթա նկարագրություն',
    },
    continueShopping: {
      en: 'Continue shopping',
      ru: 'Продолжить покупки',
      hy: 'Շարունակել գնումները',
    },
    cannotPayOnline: {
      en: 'Cannot pay online',
      ru: 'Невозможно оплатить онлайн',
      hy: 'Հնարավոր չէ վճարել առցանց',
    },
    cannotPayOnlineLong: {
      en: 'This product can be purchased by calling',
      ru: 'Этот товар можно приобрести по телефону',
      hy: 'Տվյալ ապրանքը կարելի է գնել զանգի միջոցով',
    },
    cannotPayOnlineExtraLong: {
      en: 'There is no online payment for kilo products',
      ru: 'Нет онлайн-оплаты за килограмм продукции',
      hy: 'Կիլոգրամով ապրանքների դեպքում առցանց վճարում չի գործում',
    },
    notAvailable: {
      en: 'Product not available',
      ru: 'Продукт недоступен',
      hy: 'Ապրանքը հասանելի չէ',
    },
    role: {
      en: 'Role',
      ru: 'Роль',
      hy: 'Դեր',
    },
    roles: [
      {
        _id: 'admin',
        name: { en: 'Admin', ru: 'Администратор', hy: 'Ադմին' },
      },
      {
        _id: 'manager',
        name: { en: 'Manager', ru: 'Управляющий', hy: 'Մենեջեր' },
      },
      {
        _id: 'editor',
        name: { en: 'Editor', ru: 'Редактор', hy: 'Խմբագիր' },
      },
    ],
    orderType: {
      en: 'Order Type',
      ru: 'Тип заказа',
      hy: 'Պատվերի տեսակ',
    },
    total: {
      en: 'Total',
      ru: 'Сумма',
      hy: 'Ընդամենը',
    },
    rate: {
      en: 'Rate',
      ru: 'Показатель',
      hy: 'Գործակից',
    },
    price: {
      en: 'Price',
      ru: 'Цена',
      hy: 'Գին',
    },
    company: {
      en: 'Company',
      ru: 'Компания',
      hy: 'Ընկերություն',
    },
    phone: {
      en: 'Phone',
      ru: 'Телефон',
      hy: 'Հեռախոս',
    },
    priceBasic: {
      en: 'Price Basic',
      ru: 'Базовая цена',
      hy: 'Հիմնական գինը',
    },
    priceB2b: {
      en: 'Price Corporate',
      ru: 'Цена Корпоративный',
      hy: 'Գին Կորպորատիվ',
    },
    startDate: {
      en: 'Start Date',
      ru: 'Дата начала',
      hy: 'Մեկնարկի ամսաթիվ',
    },
    endDate: {
      en: 'End Date',
      ru: 'Дата окончания',
      hy: 'Ավարտի ամսաթիվ',
    },
    status: {
      en: 'Status',
      ru: 'Статус',
      hy: 'Ստատուս',
    },
    paidStatus: {
      en: 'Paid Status',
      ru: 'Статус оплаты',
      hy: 'Վճարման ստատուս',
    },
    client: {
      en: 'Client',
      ru: 'Клиент',
      hy: 'Հաճախորդ',
    },
    genders: [
      {
        name: {
          en: 'Male',
          ru: 'Мужчина',
          hy: 'Արական',
        },
      },
      {
        name: {
          en: 'Female',
          ru: 'Женщина',
          hy: 'իգական',
        },
      },
    ],
    gender: {
      en: 'Gender',
      ru: 'Пол',
      hy: 'Սեռ',
    },
    familyStatuses: [
      {
        name: {
          en: 'Single',
          ru: 'Одинокий',
          hy: 'Միայնակ',
        },
      },
      {
        name: {
          en: 'Married',
          ru: 'В браке',
          hy: 'Ամուսնացած',
        },
      },
    ],
    more: {
      en: 'Additional',
      ru: 'Дополнительный',
      hy: 'Լրացուցիչ',
    },
    addNew: {
      en: 'Add New',
      ru: 'Добавить',
      hy: 'Ավելացնել',
    },
    create: {
      en: 'Create',
      ru: 'Создать',
      hy: 'Ստեղծել',
    },
    save: {
      en: 'Save',
      ru: 'Сохранить',
      hy: 'Պահպանել',
    },
    sent: {
      en: 'Sent',
      ru: 'Отправленно',
      hy: 'Ուղարկված է',
    },
    send: {
      en: 'Send',
      ru: 'Отправить',
      hy: 'Ուղարկել',
    },
    open: {
      en: 'Open',
      ru: 'Открыть',
      hy: 'Բացել',
    },
    found: {
      en: 'Found',
      ru: 'Найдено',
      hy: 'Գտնվեց',
    },
    remove: {
      en: 'Remove',
      ru: 'Удалить',
      hy: 'Ջնջել',
    },
    removed: {
      en: 'Removed',
      ru: 'Удалена',
      hy: 'Ջնջված է',
    },
    removeCart: {
      en: 'Delete cart',
      ru: 'Удалить корзину',
      hy: 'Ջնջել զամբյուղը',
    },
    thisProductPlacedinItsPlace: {
      en: 'This product placed in its place',
      ru: 'Данный продукт помещен на свое место',
      hy: 'Տվյալ ապրանքը տեղադրվեց իր տեղում',
    },
    cancel: {
      en: 'Cancel',
      ru: 'Отменить',
      hy: 'Չեղարկել',
    },
    canceled: {
      en: 'Canceled',
      ru: 'Отменен',
      hy: 'Չեղարկված է',
    },
    theBasketIsInPlace: {
      en: 'The basket is in place',
      ru: 'Корзина на месте',
      hy: 'Զամբյուղը տեղում է',
    },
    edit: {
      en: 'Edit',
      ru: 'Редактировать',
      hy: 'Խմբագրել',
    },
    write: {
      en: 'Write',
      ru: 'Написать',
      hy: 'Գրել',
    },
    selectSomething: {
      en: 'Select something',
      ru: 'Выберите что-нибудь',
      hy: 'Ընտրեք մի բան',
    },
    close: {
      en: 'Close',
      ru: 'Закрыть',
      hy: 'Փակել',
    },
    closeTicket: {
      en: 'Close Ticket',
      ru: 'Закрыть билет',
      hy: 'Փակել տոմսը',
    },
    index: {
      en: 'Index',
      ru: 'Индекс',
      hy: 'Ինդեքս',
    },
    developed: {
      en: 'Developed',
      ru: 'Разработанно',
      hy: 'Ստեղծված է',
    },
    makePurchases: {
      en: 'Make purchases',
      ru: 'Совершить покупки',
      hy: 'Կատարել գնումներ',
    },
    order: {
      en: 'Order',
      ru: 'Заказать',
      hy: 'Պատվիրել',
    },
    version: {
      en: 'Version',
      ru: 'Версия',
      hy: 'Տարբերակ',
    },
    mainInformation: {
      en: 'Basic information',
      ru: 'Основная информация',
      hy: 'Հիմնական տեղեկատվություն',
    },
    menu: {
      order: {
        name: { en: 'Order', ru: 'Заказ', hy: 'Պատվեր' },
        icon: 'list-order-svgrepo-com.svg',
        route: 'order',
      },
      clientGroup: {
        en: 'Client Groups',
        ru: 'Группы клиентов',
        hy: 'Հաճախորդների խմբեր',
      },
      store: {
        en: 'Store',
        ru: 'Магазин',
        hy: 'Խանութ-սրահ',
      },
      kitchen: {
        en: 'Kitchen',
        ru: 'Кухня',
        hy: 'Խոհանոց',
      },
      cart: {
        en: 'Cart',
        ru: 'Карзина',
        hy: 'Զամբյուղ',
      },
      jobs: {
        en: 'Work with us',
        ru: 'Работать у нас',
        hy: 'Աշխատանք մեզ մոտ',
      },
    },
    deliveryInfo: {
      city: {
        en: 'City',
        ru: 'Город',
        hy: 'Քաղաք',
      },
    },
  },
  menu: [
    {
      name: { en: 'Corporate', ru: 'Корпоративный', hy: 'Կորպորատիվ' },
      route: 'corporate',
    },
    {
      name: {
        en: 'Delivery',
        ru: 'Доставка',
        hy: 'Առաքում',
      },
      route: 'delivery-and-payment',
    },
    {
      name: { en: 'About Us', ru: 'О нас', hy: 'Մեր մասին' },
      route: 'about-us',
    },
    {
      name: { en: 'Menu', ru: 'Меню', hy: 'Մենյու' },
      route: '',
      fill: true,
    },
    {
      name: { en: 'Fish Coin', ru: 'Fish Coin', hy: 'Բոնուս' },
      route: 'fishcoin',
    },
    {
      name: { en: 'Sale', ru: 'Акция', hy: 'Զեղչված' },
      route: 'sale',
    },
    {
      name: { en: 'Contacts', ru: 'Контакты', hy: 'Կապ' },
      route: 'contact',
    },
  ],
  ARMENIAN_CITIES: [
    {
      label: {
        en: 'Yerevan',
        ru: 'Ереван',
        hy: 'Երևան',
      },
      value: 'yerevan',
      price: 1000,
    },
    {
      label: {
        en: 'Gyumri',
        ru: 'Гюмри',
        hy: 'Գյումրի',
      },
      value: 'gyumri',
      price: 12000,
    },
    {
      label: {
        en: 'Ejmiacin',
        ru: 'Эджмиацин',
        hy: 'Էջմիածին',
      },
      value: 'ejmiacin',
      price: 5000,
    },
    {
      label: {
        en: 'Dzoraghbyur',
        ru: 'Дзорахбюр',
        hy: 'Ձորաղբյուր',
      },
      value: 'dzoraghbyur',
      price: 1500,
    },
    {
      label: {
        en: 'Abovyan',
        ru: 'Абовян',
        hy: 'Աբովյան',
      },
      value: 'abovyan',
      price: 1700,
    },
    {
      label: {
        en: 'Ararat',
        ru: 'Арарат',
        hy: 'Արարատ',
      },
      value: 'ararat',
      price: 5800,
    },
    {
      label: {
				en: 'Geghanist',
				ru: 'Геханист',
				hy: 'Գեղանիստ',
			  },
      value: 'geghanist',
      price: 1500,
    },
  ],
};

export default config;
