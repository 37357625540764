import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGlobal } from '../helpers/globalContext';
import config from '../../config';

export const Bredcrumbs = React.memo(() => {
  const { lang, bredCrumbsCategory, setbredCrumbsCategory, mainRoute } =
    useGlobal();
  const location = useLocation();

  useEffect(() => {}, [bredCrumbsCategory]);
  useEffect(() => {}, [location.pathname]);

  return (
    <div className='bredcrumbs'>
      {location.pathname !== '/' && (
        <>
          <Link
            className='bredcrumbs-item'
            to={mainRoute}
            style={{ height: location.pathname !== '/' ? '20' : '1' }}>
            <img
              src='/assets/home-svgrepo-com.svg'
              alt='account'
              width={20}
              height={20}
            />
          </Link>
          <span className='bredcrumbs-arrow'>&gt;</span>

          {location.pathname === '/market/store' ? (
            <Link
              className='bredcrumbs-item'
              to={`${mainRoute}market/store/`}
              onClick={setbredCrumbsCategory(null)}>
              <p className='account-icon-text'>
                {config.translate.menu.store[lang]}
              </p>
            </Link>
          ) : location.pathname.includes('/market/store') &&
            bredCrumbsCategory?.href ? (
            <Link className='bredcrumbs-item' to={`${mainRoute}market/store/`}>
              <p className='account-icon-text'>
                {config.translate.menu.store[lang]}{' '}
                <span className='bredcrumbs-arrow'>&gt;</span>{' '}
                {bredCrumbsCategory.name[lang]}
              </p>
            </Link>
          ) : null}
          {location.pathname.includes('/cart') && (
            <Link className='bredcrumbs-item' to={`${mainRoute}market/cart/`}>
              <p className='account-icon-text'>
                {config.translate.menu.cart[lang]}
              </p>
            </Link>
          )}
          {location.pathname.includes('/login') && (
            <Link className='bredcrumbs-item' to={`${mainRoute}market/cart/`}>
              <p className='account-icon-text'>
                {config.translate.logIn[lang]}
              </p>
            </Link>
          )}
          {location.pathname.includes('/account') && (
            <Link className='bredcrumbs-item' to={`${mainRoute}market/cart/`}>
              <p className='account-icon-text'>
                {config.translate.personalInfo[lang]}
              </p>
            </Link>
          )}
          {location.pathname.includes('/market/kitchen') && (
            <Link className='bredcrumbs-item' to={`${mainRoute}market/kitchen/`}>
              <p className='account-icon-text'>
                {config.translate.menu.kitchen[lang]}
              </p>
            </Link>
          )}
          {config.menu.map((item, index) => {
            if (
              location.pathname !== '/market/kitchen' &&
              location.pathname !== '/market/store' &&
              item.fill !== true &&
              location.pathname.includes(item.route)
            )
              return (
                <Link
                  className='bredcrumbs-item'
                  to={`${mainRoute}${item.route}/`}>
                  <p className='account-icon-text'>{item.name[lang]}</p>
                </Link>
              );
          })}
        </>
      )}
    </div>
  );
});
