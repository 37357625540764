import React from 'react';
import { CartIcon } from './cart_icon';
import { SearchIcon } from './search_icon';
import { AccountIcon } from './account_icon';
import { LanguageBar } from '../language_bar';

export default function HeaderElements({ cart }) {
  return (
    <div className='header-elements'>
      <div className='header-elements-login'>
        <AccountIcon />
      </div>
      <div className='header-elements-cart'>
        <CartIcon {...cart} />
      </div>
      <div className='header-elements-search'>
        <SearchIcon />
      </div>
      <div className='header-elements-language'>
        <LanguageBar />
      </div>
    </div>
  );
}
