import React, { useState, useEffect, lazy } from 'react';

// import Navigation from '../navigation/navigation';
import { Logo } from '../logo';
import HeaderElements from './header_elements';
import { useHistory, useLocation } from 'react-router-dom';
import config from '../../../config';
import * as API from '../../helpers/api';
import { SumTotal } from '../../helpers/sum';
import { useGlobal } from '../../helpers/globalContext';
import { LazyLoadComponent } from '../../helpers/lazyLoadComponents';

const Navigation = lazy(() => import('../navigation/navigation'));

export default function Header() {
  const history = useHistory();
  const [logoType, setLogoType] = useState('');
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openDesktopMenu, setOpenDesktopMenu] = useState(false);

  const { cart, setCartData, user, lang } = useGlobal();
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [isBusy, setBusy] = useState(true);
  const [loadNavigation, setLoadNavigation] = useState(true);
  const location = useLocation();

  const [categories, setCategories] = useState(false);

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };
  history.listen((res) => {
    let Market = history?.location.pathname?.split('/')[2];
    if (Market === 'kitchen') {
      setLogoType('red');
    } else if (Market === 'store') {
      setLogoType('blue');
    }
  });

  useEffect(() => {
    let Market = history?.location.pathname?.split('/')[2];
    if (Market === 'kitchen') {
      setLogoType('red');
    } else if (Market === 'store') {
      setLogoType('blue');
    }
    // eslint-disable-next-line
  }, [logoType]);

  useEffect(() => {
    (async () => {
      if (cart || Array.isArray(cart)) {
        setQuantity(cart.length);
        let cartIds = cart?.map((item) => item.product_id);

        let rawData = await API.get(
          config.api.product,
          { _id: { $in: cartIds } },
          { index: 1 },
          true
        );
        rawData = rawData.map((item) => ({ ...item, checked: true }));
        // ! Routes
        //route, query, sort, uploadType, tags, sale, NoNotAvailable, top;
        setCartData(rawData);
        setTotal(SumTotal(cart, rawData, user));
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy, cart]);

  useEffect(() => {
    (async () => {
      let categories = await API.get(
        config.api.category,
        {},
        { index: 1 },
        true
      );
      setCategories(categories);
      setLoadNavigation(true);
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <header className='app-header'>
      <div className='app-header-background'></div>
      <Logo mode={logoType} />
      {categories && (
        <LazyLoadComponent
          component={
            <Navigation
              openMobileMenu={openMobileMenu}
              setOpenMobileMenu={setOpenMobileMenu}
              openDesktopMenu={openDesktopMenu}
              setOpenDesktopMenu={setOpenDesktopMenu}
              activeRoute={activeRoute}
              categories={categories}
            />
          }
          state={true}
        />
      )}
      <div className='phone-link'>
        <div className='phone-link-container'>
          <a
            href='tel:+37433004343'
            className='phone-link-container-item'
            aria-label='tel'>
            <img
              width='30'
              height='30'
              src='/assets/carbon_phone_blue.svg'
              className='phone-link-container-item-icon'
              alt='phone-number'
            />
            <p className='phone-link-item-text'>033 00 43 43</p>
          </a>
          <a
            href='tel:+37411343434'
            className='phone-link-container-item'
            aria-label='tel'>
            <img
              width='30'
              height='30'
              src='/assets/carbon_phone_red.svg'
              className='phone-link-container-item-icon'
              alt='phone-number'
            />
            <p className='phone-link-item-text'>011 34 34 34</p>
          </a>
        </div>

        <a
          href='https://api.whatsapp.com/send/?phone=%2B37411343434'
          className={'phone-link-item'}
          aria-label='tel'>
          <img
            width='30'
            height='30'
            src='/assets/whatsapp.svg'
            className='phone-link-item-icon'
            alt='phone-number'
          />
        </a>
        <a
          href='viber://chat?number=%237411343434'
          className={'phone-link-item'}
          aria-label='tel'>
          <img
            width='30'
            height='30'
            src='/assets/viber.svg'
            className='phone-link-item-icon'
            alt='phone-number'
          />
        </a>
      </div>
      <HeaderElements
        setOpenMobileMenu={setOpenMobileMenu}
        cart={{
          cart,
          setCartData,
          total,
          setTotal,
          quantity,
          setQuantity,
          user,
        }}
      />
    </header>
  );
}
