import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from '../helpers/globalContext';

export function Logo({ mode }) {
  let { mainRoute } = useGlobal();
  return (
    <Link to={mainRoute} className='logo' aria-label='Go to home page'>
      <img
        src={`/assets/logo_${mode ? mode : 'blue'}.svg`}
        alt='logotip'
        width={90}
        height={50}
      />
    </Link>
  );
}
