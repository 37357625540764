import { useEffect, useState } from 'react';

export const useStateWithLocalStorage = (localStorageKey, type) => {
  let parsedValue = '';
  let tempValue = JSON.stringify(localStorage.getItem(localStorageKey));
  if (
    tempValue &&
    JSON.parse(tempValue) !== null &&
    JSON.parse(tempValue) !== undefined &&
    JSON.parse(tempValue) !== ''
  ) {
    parsedValue = JSON.parse(tempValue);

    try {
      parsedValue = JSON.parse(parsedValue);
    } catch (error) {
      parsedValue = parsedValue;
    }

    if (type === 'array' && !Array.isArray(parsedValue)) {
      parsedValue = [];
    } else if (type === 'object' && typeof parsedValue !== 'object') {
      parsedValue = {};
    } else if (type === 'string' && typeof parsedValue !== 'string') {
      parsedValue = '';
    } else if (type === 'number' && typeof parsedValue !== 'number') {
      parsedValue = 0;
    } else if (type === 'boolean' && typeof parsedValue !== 'boolean') {
      parsedValue = false;
    } else if (type === 'function' && typeof parsedValue !== 'function') {
      parsedValue = () => {};
    } else if (type === 'symbol' && typeof parsedValue !== 'symbol') {
      parsedValue = Symbol();
    }
  } else {
    if (type === 'array' && !Array.isArray(parsedValue)) {
      parsedValue = [];
    } else if (type === 'object' && typeof parsedValue !== 'object') {
      parsedValue = {};
    } else if (type === 'string' && typeof parsedValue !== 'string') {
      parsedValue = '';
    } else if (type === 'number' && typeof parsedValue !== 'number') {
      parsedValue = 0;
    } else if (type === 'boolean' && typeof parsedValue !== 'boolean') {
      parsedValue = false;
    } else if (type === 'function' && typeof parsedValue !== 'function') {
      parsedValue = () => {};
    } else if (type === 'symbol' && typeof parsedValue !== 'symbol') {
      parsedValue = Symbol();
    }
  }

  const [value, setValue] = useState(parsedValue);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  return [value, setValue];
};
