import React from 'react';
import { Link } from 'react-router-dom';
import Tada from 'react-reveal/Tada';
import { useGlobal } from '../../helpers/globalContext';

export const CartIcon = React.memo(function ({ cart, total, quantity }) {
  const { mainRoute } = useGlobal();
  return (
    <Link to={`${mainRoute}cart/`}>
      <Tada spy={cart}>
        <div className={'cart-icon'}>
          <img src='/assets/Cart_icon.svg' alt='cart' />
          <div className='cart-quantity'>{quantity}</div>
          <div className='cart-total'>{total}դ</div>
        </div>
      </Tada>
    </Link>
  );
});
