import Cookies from 'universal-cookie';
import config from '../../config';
export const cookies = new Cookies();

export let keyFile = cookies.get(config.authentication.cookieKeyFile)
  ? cookies.get(config.authentication.cookieKeyFile)
  : process.env.REACT_APP_API_KEY;

export const setCookieKeyFile = (data) => {
  keyFile = cookies.get(config.authentication.cookieKeyFile);
  let date = new Date();
  date.setTime(date.getTime() + date.minutes * 60 * 1000);
  cookies.set(config.authentication.cookieKeyFile, data, {
    path: '/',
    expires: date,
  });
};

export let token = cookies.get(config.authentication.cookieToken);
export const setCookieToken = (data) => {
  token = cookies.get(config.authentication.cookieToken);
  let date = new Date();
  date.setTime(date.getTime() + date.minutes * 60 * 100);
  cookies.set(config.authentication.cookieToken, data, {
    path: '/',
    expires: date,
  });
};
export const clearAllCookies = () => {
  cookies.remove('client-token', { path: '/' });
  cookies.remove('private-token', { path: '/' });
  cookies.remove('keyFile', { path: '/' });
};
