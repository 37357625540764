import React, { useState, Suspense, useEffect, lazy } from "react";
// Helpers
import history from "./react_components/helpers/history.jsx";
import { useStateWithLocalStorage } from "./react_components/helpers/storage.jsx";

import "./sass_components/App.sass";

import { GlobalContext } from "./react_components/helpers/globalContext.jsx";
import {
  cookies,
  setCookieToken,
  clearAllCookies,
} from "./react_components/helpers/cookies.jsx";
import { ModalProvider } from "./react_components/helpers/modal_provider.jsx";
import * as API from "./react_components/helpers/api.jsx";
import config from "./config";
// eslint-disable-next-line
// import Cursor from "./react_components/helpers/cursor";
import Loading from "./react_components/helpers/loading.jsx";
// Routing
import { Router, Route, Switch, Redirect } from "react-router-dom";

// import Footer from './react_components/reusable-components/footer';
import Header from "./react_components/reusable-components/header/header.jsx";
import { LazyLoadComponent } from "./react_components/helpers/lazyLoadComponents";

const Footer = lazy(() =>
  import("./react_components/reusable-components/footer.jsx")
);
const ModalWrapper = lazy(() =>
  import("./react_components/helpers/modal_wrapper.jsx")
);

// const Swal = React.lazy(() => import('sweetalert2'));
import Swal from "sweetalert2";
import { Bredcrumbs } from "./react_components/reusable-components/bredcrumbs.jsx";

const Home = React.lazy(() => import("./react_components/pages/home.jsx"));
const AboutUs = React.lazy(() =>
  import("./react_components/pages/aboutus.jsx")
);
const Market = React.lazy(() => import("./react_components/pages/market.jsx"));
const Contact = React.lazy(() =>
  import("./react_components/pages/contact.jsx")
);
const CartPage = React.lazy(() => import("./react_components/pages/cart.jsx"));
const CheckOrder = React.lazy(() =>
  import("./react_components/pages/checkOrder.jsx")
);
const Login = React.lazy(() => import("./react_components/pages/login.jsx"));
const Account = React.lazy(() =>
  import("./react_components/pages/account.jsx")
);
const DeliveryAndPayment = React.lazy(() =>
  import("./react_components/pages/deliveryAndPayment.jsx")
);
const Corporate = React.lazy(() =>
  import("./react_components/pages/corporate.jsx")
);
const Bonus = React.lazy(() => import("./react_components/pages/bonus.jsx"));
const Page404 = React.lazy(() =>
  import("./react_components/pages/page404.jsx")
);
const Sale = React.lazy(() => import("./react_components/pages/sale.jsx"));
const PrivacyPolicy = React.lazy(() =>
  import("./react_components/pages/privacyPolicy.jsx")
);
const TermsOfService = React.lazy(() =>
  import("./react_components/pages/termsOfService.jsx")
);
function RouterComponent({ pathLang, lang }) {
  if (!!pathLang) {
    return (
      <Switch>
        <Redirect exact from="/" to={lang} />
        <Route exact path={`/${pathLang}/`} component={Home} />
        <Route exact path={`/${pathLang}/about-us`} component={AboutUs} />
        <Route exact path={`/${pathLang}/contact`} component={Contact} />
        <Route exact path={`/${pathLang}/check-order`} component={CheckOrder} />
        <Route exact path={`/${pathLang}/cart`} component={CartPage} />
        <Route exact path={`/${pathLang}/product/:id`} component={Home} />
        <Route exact path={`/${pathLang}/market/:market`} component={Market} />
        <Route
          exact
          path={`/${pathLang}/market/:market/:category`}
          component={Market}
        />
        <Route exact path={`/${pathLang}/login`} component={Login} />
        <Route exact path={`/${pathLang}/account`} component={Account} />
        <Route exact path={`/${pathLang}/sale`} component={Sale} />
        <Route
          exact
          path={`/${pathLang}/delivery-and-payment`}
          component={DeliveryAndPayment}
        />
        <Route exact path={`/${pathLang}/corporate`} component={Corporate} />
        <Route exact path={`/${pathLang}/fishcoin`} component={Bonus} />
        <Route
          exact
          path={`/${pathLang}/${config.privacyPolicy.link}`}
          component={PrivacyPolicy}
        />
        <Route
          exact
          path={`/${pathLang}/${config.termsOfService.link}`}
          component={TermsOfService}
        />
        <Route exact path={`/404`} component={Page404} status={404} />
        <Route path={`*`} component={Page404} status={404} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Redirect exact from="/" to={lang} />
        <Route exact path={`/check-order`} component={CheckOrder} />
        <Route path={`*`} component={Page404} status={404} />
      </Switch>
    );
  }
}
let firstTimeModalOpened = false;

function App() {
  let [lang, setLang] = useStateWithLocalStorage("language");
  let [cart, setCart] = useStateWithLocalStorage("cart", "array");
  let [cartData, setCartData] = useState(undefined);
  let [favorites, setFavorites] = useState();
  let [loggedIn, setLoggedIn] = useState(Boolean);
  let [keyFile, setKeyFile] = useState(Boolean);
  let [user, setUser] = useState({});
  let [open, setOpen] = useState(false);
  let [bredCrumbsCategory, setbredCrumbsCategory] = useState(false);
  let [modalFor, setModalFor] = useState("");
  let url = new URL(window.location.href);
  let restParams = {
    lang: url.searchParams.get("lang"),
  };
  let pathLangUrl = url.pathname.split("/")[1];
  let pathLang =
    pathLangUrl === "en"
      ? "en"
      : pathLangUrl === "ru"
      ? "ru"
      : pathLangUrl === "hy"
      ? "hy"
      : false;
  let [mainRoute, setMainRoute] = useState(
    pathLang ? `/${pathLang}/` : `/${lang}/`
  );
  const [adult, setAdult] = useState(undefined);

  async function logout() {
    setCookieToken("");
    setLoggedIn(false);
    setUser({});
  }
  function login(username, password) {
    if (username && password) {
      API.post(config.authentication.loginToken, {
        username,
        password,
      })
        .then((res) => {
          API.settings.headers.token = res.token;
          if (res.client) {
            setUser(res.client);
            res.client.cart &&
              setCart(JSON.parse(JSON.stringify(res.client.cart)));
          }
          setCookieToken(res.token);
          setLoggedIn(true);
        })
        .catch((error) => {
          Swal.fire(config.swal.thereIsNoUser[lang]);
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      Swal.fire.error("Մուտքագրված տվյալների սխալմունք");
      console.log(
        "%c There is no such a user! ",
        "background: #222; color: #04b4e0"
      );
    }
  }
  function verifySMS(username, continueProcess) {
    if (username) {
      return new Promise((resolve, reject) => {
        API.post(config.authentication.verifySMS, {
          username,
        })
          .then((res) => {
            if (res.randomNumber) {
              Swal.fire({
                title: config.translate.submitSMS[lang],
                input: "number",
                inputAttributes: {
                  autocapitalize: "off",
                },
                showCancelButton: true,
                cancelButtonText: config.translate.cancel[lang],
                confirmButtonText: config.translate.submit[lang],
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                  if (+res.randomNumber === +login) {
                    resolve(true);
                  } else {
                    Swal.showValidationMessage(config.translate.smsError[lang]);
                    reject(false);
                  }
                },
                allowOutsideClick: () => !Swal.isLoading(),
              }).then((result) => {
                if (continueProcess) {
                  resolve();
                } else if (result.isConfirmed) {
                  Swal.fire({
                    title: `Հաջողությամբ գրանցվեցիք`,
                  });
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire(
              config.swal.websiteFormError(error?.response?.data?.errors)[lang]
            );
            console.error(error);
            setLoggedIn(false);
          });
      });
    } else {
      setLoggedIn(false);
      console.log(
        "%c There is no such a user! ",
        "background: #222; color: #04b4e0"
      );
    }
  }
  function register(
    username,
    password,
    address,
    firstName,
    telegram,
    viber,
    whatsapp
  ) {
    if (username && password) {
      return new Promise((resolve, reject) => {
        API.post(config.authentication.registerClient, {
          username,
          password,
          address,
          firstName,
          telegram,
          viber,
          whatsapp,
        })
          .then((res) => {
            API.settings.headers.token = res.token;
            if (res.client) {
              setUser(res.client);
              res.client.cart &&
                setCart(JSON.parse(JSON.stringify(res.client.cart)));
            }
            resolve(res);
            setCookieToken(res.token);
            setLoggedIn(true);
          })
          .catch((error) => {
            error.response.data
              ? Swal.fire(
                  config.swal.websiteFormError(error.response.data?.errors)[
                    lang
                  ]
                )
              : Swal.fire(
                  config.swal.websiteFormError(error.response.error)[lang]
                );
            reject(error);
            console.error(error);
            setLoggedIn(false);
          });
      });
    } else {
      setLoggedIn(false);
      console.log(
        "%c There is no such a user! ",
        "background: #222; color: #04b4e0"
      );
    }
  }
  function order(modelSendToServer, token, socket, emailjs) {
    if (modelSendToServer) {
      if (token) API.settings.headers.token = token;
      API.post(config.authentication.registerOrder, modelSendToServer)
        .then((res) => {
          socket.emit("registerOrder", res.order); // res.order to res
          if (res.client) {
            setUser(res.client);
            res.client.cart &&
              setCart(JSON.parse(JSON.stringify(res.client.cart)));
          }
          // if (emailjs) {
          // 	let formatedOrder = {
          // 		...res.order,
          // 		formatedDate:
          // 			new Date(res.order.date).getHours() +
          // 			':' +
          // 			new Date(res.order.date).getMinutes() +
          // 			' | ' +
          // 			new Date(res.order.date).getDate() +
          // 			'/' +
          // 			(parseInt(new Date(res.order.date).getMonth()) +
          // 				1) +
          // 			'/' +
          // 			new Date(res.order.date).getFullYear(),
          // 	};
          // 	emailjs.send(
          // 		'service_uiee1do',
          // 		'template_2hwd7n9',
          // 		formatedOrder,
          // 		'ngf5Bw6rQ8D_vSWC-'
          // 	);
          // }
          setCartData([]);
          setCart([]);
          <Redirect to={`/${pathLang}/account`} />;
          Swal.fire(config.swal.successOrder(lang, res?.order?.ID));
        })
        .catch((error) => {
          Swal.fire(config.swal.errorOrder[lang]);
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      console.log(
        "%c ModelSendToServer is not provided! ",
        "background: #222; color: #04b4e0"
      );
    }
  }
  function orderIncognito(modelSendToServer, token) {
    if (modelSendToServer) {
      if (token) API.settings.headers.token = token;
      return new Promise((resolve, reject) => {
        API.post(config.authentication.registerOrder, modelSendToServer)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
            Swal.fire(config.swal.errorOrder[lang]);
            console.error(error);
            setLoggedIn(false);
          });
      });
    } else {
      setLoggedIn(false);
      console.log(
        "%c ModelSendToServer is not provided! ",
        "background: #222; color: #04b4e0"
      );
    }
  }
  function update(route, id, object, noAlert) {
    API.update(route, id, object)
      .then((res) => {
        API.settings.headers.token = res.token;
        if (res.client) {
          setUser(res.client);
          if (noAlert) {
          } else {
            Swal.fire(config.swal.successfullyUpdated[lang]);
          }
        }
        setLoggedIn(true);
        setCookieToken(res.token);
      })
      .catch((error) => {
        if (noAlert) {
        } else {
          Swal.fire(config.swal.error[lang]);
        }
        console.error(error);
      });
  }
  function checkToken() {
    let cookieToken = cookies.get(config.authentication.cookieToken);
    if (!!cookieToken && cookieToken !== "undefined") {
      API.post(config.authentication.verifyToken, {
        token: cookieToken,
      })
        .then((res) => {
          if (res) {
            if (res.status === "success" || res.status === 200) {
              if (res.client) {
                setUser(res.client);
                setCart(JSON.parse(JSON.stringify(res.client.cart)));
              }
              setLoggedIn(true);
            }
            // setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(`Please don't edit cookies manually`);
          clearAllCookies();
          setUser();
          setLoggedIn(false);
          // window.location.reload(false); //? reload webpage
        });
    } else {
      API.post(config.authentication.verifyKeyFile, {
        keyFile: config.authentication.key,
      })
        .then((res) => {
          if (res.status === "success" || res.status === 200) {
            setKeyFile(true);
          }
        })
        .catch((res) => {
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          clearAllCookies();
          setKeyFile(false);
          // window.location.reload(false); //? reload keyfile
        });
    }
  }
  const openModal = (data, firstTime) => {
    document.body.classList.add("show-modal");
    data &&
      window.history.pushState(
        `${mainRoute}${data?.href}`,
        "",
        `${mainRoute}product/${data?.href}`
      );
    setModalFor(data);
    setOpen(true);
    if (firstTime) {
      firstTimeModalOpened = true;
    }
  };
  const closeModal = (goBack) => {
    document.body.classList.remove("show-modal");
    if (goBack) {
      if (firstTimeModalOpened) {
        window.history.pushState("", "", `${mainRoute}`);
        firstTimeModalOpened = false;
      } else {
        window.history.go(-1);
      }
    }
    setOpen(false);
  };
  const modalToggle = () => {
    if (!open) document.body.classList.add("show-modal");
    else document.body.classList.remove("show-modal");
    setOpen(!open);
  };

  useEffect(() => {
    const handleScroll = (e) => {
      let header = document.querySelector(".app-header");
      let element = e.target.body;
      if (window.scrollY > 1) {
        header?.classList.add("fixed-header");
      } else if (window.scrollY <= 1) {
        header?.classList.remove("fixed-header");
      }
    };
    document.addEventListener("scroll", handleScroll, { passive: true });
    if (pathLang) {
      if (pathLang === "en") setLang("en");
      else if (pathLang === "ru") setLang("ru");
      else if (pathLang === "hy") setLang("hy");
      else setLang("hy");
    } else if (restParams.lang) {
      if (restParams.lang === "en") setLang("en");
      else if (restParams.lang === "ru") setLang("ru");
      else if (restParams.lang === "hy") setLang("hy");
      else setLang("hy");
    } else if (lang) {
      if (lang === "en") setLang("en");
      else if (lang === "ru") setLang("ru");
      else if (lang === "hy") setLang("hy");
      else setLang("hy");
    } else {
      setLang("hy");
    }

    (async () => {
      await checkToken();
    })();

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [loggedIn, mainRoute]);

  if (loggedIn === true || keyFile === true) {
    return (
      <GlobalContext.Provider
        value={{
          login,
          logout,
          mainRoute,
          setMainRoute,
          loggedIn,
          register,
          update,
          verifySMS,
          order,
          user,
          favorites,
          setFavorites,
          cart,
          setCart,
          orderIncognito,
          cartData,
          setCartData,
          lang,
          setLang,
          adult,
          setAdult,
          bredCrumbsCategory,
          setbredCrumbsCategory,
        }}
      >
        <ModalProvider.Provider
          value={{
            open,
            setOpen,
            modalToggle,
            modalFor,
            setModalFor,
            openModal,
            closeModal,
          }}
        >
          <LazyLoadComponent component={<ModalWrapper />} />
          <Router history={history}>
            <Switch>
              <Suspense fallback={<Loading className="loader" />}>
                <Header />
                <Bredcrumbs />
                <div className="app-content">
                  <RouterComponent pathLang={pathLang} lang={lang} />
                </div>
                <LazyLoadComponent component={<Footer />} />
              </Suspense>
            </Switch>
          </Router>
        </ModalProvider.Provider>
      </GlobalContext.Provider>
    );
  } else {
    return <Loading className="loader" />;
  }
}

export default App;
